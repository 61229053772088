import React from "react";
import PropTypes from "prop-types";
import { OMSDropdown } from "../../../app/common/common-components/OMSDropdown";
import { toast } from "react-toastify";
import { OMSButtonStyleL } from "../../../app/common/common-components/OMSButton";
import apiAgent from "../../../app/api/apiAgent";
import { withRouter } from "react-router-dom";
import BrochureList from "./ReportStepChildComponents/BrochureList";
import MatchingClientsModal from "./ReportStepChildComponents/MatchingClientsModal";
import ListingViewsModal from "./ReportStepChildComponents/ListingViewsModal";
import moment from "moment";

class ReportStepView extends React.Component {
  static propTypes = {
    PropertyID: PropTypes.string,
    UserID: PropTypes.string,
    Mandate_ExpiryDate: PropTypes.string,
    Mandate_StartDate: PropTypes.string,
  };

  constructor(props) {
    super(props);
    const mViewReportFrom = props.Mandate_StartDate ? moment(props.Mandate_StartDate, "DD-MM-YYYY").toDate() : new Date();
    const mViewReportTo = props.Mandate_ExpiryDate ? moment(props.Mandate_ExpiryDate, "DD-MM-YYYY").toDate() : new Date();
    this.state = {
      processing: false,
      mPropertyType: true,
      mSaleType: true,
      mPriceRange: true,
      mAreas: true,
      mBuildsize: true,
      mLandsize: true,
      mBeds: true,
      mBaths: true,
      mGarages: true,
      mParkingBays: true,
      mMatchingClients: [],
      mProcessing: false,
      SelectedAgentID: props.SelectedAgentID,
      mViewReportFrom,
      mViewReportTo,
    };
  }

  formatDate = (fullDateString) => {
    const date = new Date(fullDateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear());
    return `${day}-${month}-${year}`;
  };

  handleFilterOptionClick = (name) => {
    this.setState({ [name]: !this.state[name] });
  };

  handleDropdownFieldChange = (e) => {
    this.setState({ SelectedAgentID: e.newValue });
  };

  doSetState = (data) => {};

  doGetState = () => {
    return {};
  };

  handleGenerateBrochureDynamicClick = (BrochureID, IsCrystalReport, exportType) => {
    var mPropertyID = this.props.PropertyID;
    var mShowAddress = window.$("#ShowAddress").is(":checked");
    var mSelectedAgentID = this.state.SelectedAgentID;

    if (IsCrystalReport) {
      this.setState({ processing: true });
      apiAgent.Property.generateBrochureCRAjax(mPropertyID, BrochureID, mSelectedAgentID, mShowAddress)
        .then((response) => {
          var binaryData = [];
          binaryData.push(response.data);
          const url = window.URL.createObjectURL(new Blob(binaryData, { type: exportType == "PDF" ? "application/pdf" : "image/png" }));

          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", exportType == "PDF" ? "Brochure.pdf" : "Brochure.png");

          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
        .catch((error) => {
          console.log("error inside listing Report", error);
          toast.error(error.message, "Error", "error");
        })
        .finally(() => {
          this.setState({ processing: false });
        });
    } else {
      this.setState({ processing: true });
      apiAgent.Property.generateBrochureDynamicAjax(mPropertyID, BrochureID, mSelectedAgentID, mShowAddress)
        .then((response) => {
          if (response.status == "success") {
            const a = document.createElement("a");
            a.href = response.message;
            a.setAttribute("download", exportType == "PDF" ? "Brochure.pdf" : "Brochure.png");
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          }
        })
        .catch((error) => {
          toast.error(error.message, "Error", "error");
          console.log("error inside listing Report", error);
        })
        .finally(() => {
          this.setState({ processing: false });
        });
    }
  };

  handleMatchingClientsClick = (e) => {
    this.setState({
      mPropertyType: true,
      mSaleType: true,
      mPriceRange: true,
      mAreas: true,
      mBuildsize: true,
      mLandsize: true,
      mBeds: true,
      mBaths: true,
      mGarages: true,
      mParkingBays: true,
      mMatchingClients: [],
      mProcessing: false,
    });
    window.$("#matchingReportModel").modal("show");
  };

  handleMatchingClientsRunClick = (e) => {
    this.setState({ mProcessing: true, mMatchingClients: [] });
    apiAgent.Contact.requirementsClientMatchAjax(
      this.props.PropertyID,
      this.state.mPriceRange,
      this.state.mAreas,
      this.state.mBuildsize,
      this.state.mLandsize,
      this.state.mBeds,
      this.state.mBaths,
      this.state.mGarages,
      this.state.mParkingBays
    )
      .then((response) => {
        this.setState({ mMatchingClients: response, mProcessing: false });
        if (response.length == 0) {
          toast.info("No match found");
        }
      })
      .catch((error) => {
        toast.info("Fail");
      });
  };

  handleListingViewsRunClick = (e) => {
    const { mViewReportFrom, mViewReportTo } = this.state;
    const from = mViewReportFrom ? moment(mViewReportFrom).format('DD-MM-YYYY') : "";
    const to = mViewReportTo ? moment(mViewReportTo).format('DD-MM-YYYY') : "";
    this.executeListingViewRun(from, to);
  };

  executeListingViewRun = (from, to) => {
    this.setState({ mProcessing: true });
    apiAgent.Property.listingViewReportAjax(this.props.PropertyID, from, to)
      .then((response) => {
        if (response.result == null || response.result.length == 0) toast.info("No results found.");
        else {
          this.setState({ ChartData: response.result, ChartData2: response.result2 }, function () {
            this.setGoogleChartData();
          });
        }
      })
      .catch((error) => {
        toast.info("Fail");
      })
      .finally(() => {
        this.setState({ mProcessing: false });
      });
  };

  setGoogleChartData = () => {
    var isPerPortal = document.getElementById("ViewsPerPortals").checked;
    var dataItem = isPerPortal ? this.state.ChartData : this.state.ChartData2;

    if (dataItem === undefined || dataItem == null) return;

    var arrTotal = [];
    if (dataItem.length > 1) {
      for (var i = 1; i < dataItem[0].length; i++) {
        var total = 0;
        for (var j = 1; j < dataItem.length; j++) {
          total += parseInt(dataItem[j][i]);
        }
        arrTotal.push(total);
      }
    }

    var WebRef = this.props.WebRef;
    var arrData = dataItem.map(item => item);
    const google = window.google;

    google.charts.load("current", { packages: ["corechart", "bar"] }).then(function () {
      var data = new google.visualization.arrayToDataTable(arrData);
      var view = new google.visualization.DataView(data);
      var options = {
        title: "Property view statistics #" + WebRef,
        width: "100%",
        height: 400,
        legend: { position: "top", alignment: "start", maxLines: 3 },
        bar: { groupWidth: "75%" },
        isStacked: true,
      };
      var chart = new google.visualization.ColumnChart(document.getElementById("PageViewChart"));
      google.visualization.events.addListener(chart, "ready", function () {
        if (arrTotal.length != 0) {
          var selected = window.$("#PageViewChart svg text[text-anchor='start']");
          if (selected.length == arrTotal.length + 1) {
            for (var i = 0; i < arrTotal.length; i++) {
              var mText = dataItem[0][i + 1].trim().replaceAll("-", "") + "<tspan font-weight='bold'>(" + arrTotal[i] + ")</tspan>";
              window.$("#PageViewChart svg text[text-anchor='start']")[i + 1].innerHTML = mText;
            }
          }
        }
      });
      chart.draw(data, options);
    });
  };

  handleListingViewsClick = (e) => {
    var m = moment(this.props.Mandate_ExpiryDate, "DD-MM-YYYY");
    var s = moment();
    let mViewReportTo = new Date();
    if (s.diff(m, "days") >= 0) mViewReportTo = m.toDate();
    else mViewReportTo = s.toDate();

    const mViewReportFrom = moment(this.props.Mandate_StartDate, "DD-MM-YYYY").toDate();

    this.setState({
      mViewReportFrom,
      mViewReportTo,
    }, () => {
      this.executeListingViewRun(
        moment(this.state.mViewReportFrom).format('DD-MM-YYYY'),
        moment(this.state.mViewReportTo).format('DD-MM-YYYY')
      );
      window.$("#listingViewReportModel").modal("show");
    });

    document.getElementById("PageViewChart").innerHTML = "";
    document.getElementById("ViewsPerPortals").checked = true;
    document.getElementById("spanReportViewText").innerHTML = "Views per portal";
  };

  handleViewsPerPortalsChanged = (e) => {
    var text = "Views per portal";
    if (!document.getElementById("ViewsPerPortals").checked) text = "Summary view";
    document.getElementById("spanReportViewText").innerHTML = text;
    this.setGoogleChartData();
  };

  getReportIcon = (value, tooltip) => {
    if (value === "True") {
      return <span><i className="fa fa-check-circle" title="requirements matched" style={{ color: "green" }}></i></span>;
    } else if (value === "False") {
      return <span><i className="fa fa-times-circle" title={"requirements not matched: " + tooltip} style={{ color: "red" }}></i></span>;
    } else {
      return <span><i className="fa fa-minus-circle" title="requirement not specified" style={{ color: "grey" }}></i></span>;
    }
  };

  getFormattedDate = (date) => {
    if (!date) return "";
    return moment(date).format("DD-MM-yyyy");
  };

  render() {
    const filters = [
      [
        { name: "mPriceRange", value: this.state.mPriceRange, label: "Price range" },
        { name: "mAreas", value: this.state.mAreas, label: "Areas" },
        { name: "mBuildsize", value: this.state.mBuildsize, label: "Floor size" },
        { name: "mLandsize", value: this.state.mLandsize, label: "Land size" },
      ],
      [
        { name: "mBeds", value: this.state.mBeds, label: "Beds" },
        { name: "mBaths", value: this.state.mBaths, label: "Baths" },
        { name: "mGarages", value: this.state.mGarages, label: "Garages" },
        { name: "mParkingBays", value: this.state.mParkingBays, label: "Parking bays" },
      ],
      [
        { name: "mSaleType", value: this.state.mSaleType, label: "Sale type" },
        { name: "mPropertyType", value: this.state.mPropertyType, label: "Property type" },
      ],
    ];

    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <div className="panel panel-default">
              <div className="panel-heading">Brochures</div>
              <div className="panel-body">
                <div className="form-group">
                  <span style={{ marginLeft: 12 }}>
                    Show address on brochure&nbsp;
                    <input type="checkbox" id="ShowAddress" style={{ position: "absolute", marginLeft: 8 }} name="ShowAddress" defaultChecked={false} />
                  </span>
                </div>
                <div className="form-group form-inline">
                  <span style={{ marginLeft: 12, marginRight: 8 }}>Agent name on brochure&nbsp;</span>
                  <OMSDropdown
                    id="AgentNameOnBrochure"
                    name="AgentNameOnBrochure"
                    options={this.props.AgentDD}
                    valueField="value"
                    labelField="text"
                    value={this.state.SelectedAgentID}
                    onChange={this.handleDropdownFieldChange}
                  />
                </div>
                <BrochureList
                  brochures={this.props.Brochures}
                  handleGenerateBrochureDynamicClick={this.handleGenerateBrochureDynamicClick}
                  processing={this.state.processing}
                />
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="panel panel-default">
              <div className="panel-heading">Reports</div>
              <div className="panel-body">
                {this.props.ShowChart ? (
                  <div className="form-group">
                    <OMSButtonStyleL
                      type="button"
                      ui_icon="fa-bar-chart"
                      ui_type="cancel"
                      ui_text=" Listing views"
                      ui_processing_text="Processing.."
                      processing={this.state.processing}
                      disabled={this.state.processing}
                      onClick={this.handleListingViewsClick}
                    />
                  </div>
                ) : (
                  <div className="form-group">
                    <a href="javascript:void(0)" style={{ cursor: "not-allowed", color: "#333" }} title="Not enabled for office" className="btn">
                      <span className="fa fa-bar-chart"></span> Listing views
                    </a>
                  </div>
                )}
                <div className="form-group">
                  <OMSButtonStyleL
                    type="button"
                    ui_icon="fa-file-text-o"
                    ui_type="cancel"
                    ui_text=" Matching clients"
                    ui_processing_text="Generating.."
                    processing={this.state.processing}
                    disabled={this.state.processing}
                    onClick={this.handleMatchingClientsClick}
                  />
                </div>
              </div>
            </div>
            <MatchingClientsModal
              mMatchingClients={this.state.mMatchingClients}
              mProcessing={this.state.mProcessing}
              handleMatchingClientsRunClick={this.handleMatchingClientsRunClick}
              getReportIcon={this.getReportIcon}
              handleFilterOptionClick={this.handleFilterOptionClick}
              filters={filters}
            />
            <ListingViewsModal
              mViewReportFrom={this.state.mViewReportFrom}
              mViewReportTo={this.state.mViewReportTo}
              mProcessing={this.state.mProcessing}
              handleListingViewsRunClick={this.handleListingViewsRunClick}
              handleViewsPerPortalsChanged={this.handleViewsPerPortalsChanged}
              setViewReportFrom={(date) => this.setState({ mViewReportFrom: date })}
              setViewReportTo={(date) => this.setState({ mViewReportTo: date })}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ReportStepView);
