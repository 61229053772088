import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { EventTypeAutocomplete, OMSDropdown } from "../OMSDropdown";
import { OMSButtonStyleL2 } from "../OMSButton";
import { OMSDLUpload } from "../OMSDLUpload";
import { OMSLeadModel } from "../OMSInboxComponents/OMSLeadModel";
import { OMSActivityTimeline } from "../OMSActivityTimeline";
import { OMSCalendar } from "../OMSCalendar";
import { DataTableLoading } from "../OMSDatatables";
import { OMSDiaryNoteItemDisplay } from "./OMSDiaryNoteItemDisplay";
import { ActivityFilter } from "./ActivityFilter";

import PropTypes from "prop-types";
import apiAgent from "../../../api/apiAgent";
import DatePicker from "react-datepicker";
import $ from "jquery";
import bootbox from "bootbox";
import ConfirmationModalPopup from "../../common-components/ModalPopups/ConfirmationModalPopup";

const moment = require("moment");

export class OMSDiaryNoteCalendar extends React.Component {
  static propTypes = {
    htmlID: PropTypes.string.isRequired,
    ID: PropTypes.string.isRequired,
    DNType: PropTypes.number, // 1:Contact, 2:Property, 0: Both
    onOpenDairyItemFunc: PropTypes.func.isRequired,
    Display: PropTypes.string.isRequired,
    ShowAdd: PropTypes.bool,
    ReadOnly: PropTypes.bool,
    userID: PropTypes.string,
    DiaryActionTags: PropTypes.array,
    super_access: PropTypes.bool,
    CalendarState: PropTypes.string,
    CalendarOfficeID: PropTypes.string 
  };

  static defaultProps = {
    htmlID: "",
    ID: "",
    DNType: 0,
    Display: "V",
    onOpenDairyItemFunc: undefined,
    ShowAdd: false,
    ReadOnly: false,
    userID: "",
    CalendarState: "",
    CalendarOfficeID: ""
  };

  constructor(props) {
    super(props);
    var selected = new Date();
    this.state = {
      selected_date: selected,
      data: [],
      loading: false,
      view_type: "timeline",
      selectedEvents: [],
      confirmationModalProps: {
        showModal: false,
        isConfirmed: (result) => {},
        title: "",
        body: "",
      },
    };
    this.cmpActivityTimelineRef = React.createRef();
  }

  componentDidMount() {
  
    this.loadDiaryItems(new Date(), this.props.userID, this.props.CalendarState, this.props.CalendarOfficeID);
  }

  componentWillReceiveProps(nextProps) {

    if (this.props.userID != nextProps.userID) {
      this.loadDiaryItems(new Date(), nextProps.userID,nextProps.CalendarState, nextProps.CalendarOfficeID);
    }
  }

  openCloseDeleteModal = (isOpen) => {
    let varConfirmationModalProps = this.state.confirmationModalProps;
    varConfirmationModalProps.showModal = isOpen;
    this.setState({ confirmationModalProps: varConfirmationModalProps });
  };

  showPopupAndDeleteOnConfirmation = (title, body, callback) => {
    let varConfirmationModalProps = {
      title,
      body,
      showModal: true,
      isConfirmed: (isConfirmed) => {
        if (isConfirmed) {
          //console.log("confirmed");
          callback();
        }
        this.openCloseDeleteModal(false);
      },
    };
    //console.log("inside showPopupAndDeleteOnConfirmation", varConfirmationModalProps);
    this.setState({ confirmationModalProps: varConfirmationModalProps });
  };

  loadDiaryItems = (selected_date, userID, CalendarState, CalendarOfficeID) => {
    this.setState({ loading: true, data: [] });
    var month = selected_date.getMonth() + 1;
    var year = selected_date.getFullYear();
    if (CalendarState == "Office Calendar") {
      apiAgent.Diary.diariesAjax(this.props.ID, "", this.props.DNType, month, year, CalendarOfficeID)
      .then((response) => {
        var title = moment(selected_date).format("DD MMMM YYYY");
        //console.log("diariesAjaxResponse data",response);
        this.setState({ data: response, userID: "", loading: false, selected_date: selected_date, selected_date_title: title });
      })
      .catch((error) => toast.error(error.response.data));
    }
    else{
      apiAgent.Diary.diariesAjax(this.props.ID, userID, this.props.DNType, month, year, "")
      .then((response) => {
        var title = moment(selected_date).format("DD MMMM YYYY");
        //console.log("diariesAjaxResponse data",response);
        this.setState({ data: response, userID: userID, loading: false, selected_date: selected_date, selected_date_title: title });
      })
      .catch((error) => toast.error(error.response.data));
    }
    
    /* var xhr = new XMLHttpRequest();
    xhr.open('get', '/Diary/DiariesAjax?ID=' + this.props.ID + '&SDNType=' + this.props.DNType + '&Month=' + month + '&Year=' + year + '&userID=' + UserDI, true);
    xhr.onload = function () {
      var data = JSON.parse(xhr.responseText);
      var title = moment(selected_date).format('DD MMMM YYYY');
      this.setState({ data: data, userID: userID, loading: false, selected_date: selected_date, selected_date_title: title });
    }.bind(this);
    xhr.send(); */
   
  };

  onCalendarChange = (e) => {
    if (e.getMonth() == this.state.selected_date.getMonth() && e.getFullYear() == this.state.selected_date.getFullYear()) {
      var title = moment(e).format("DD MMMM YYYY");
      this.setState({ selected_date: e, selected_date_title: title });
    } else this.loadDiaryItems(e, this.state.userID, this.props.CalendarState, this.props.CalendarOfficeID);
  };

  onDiaryItemSubmit = (e) => {
    this.loadDiaryItems(this.state.selected_date, this.state.userID, this.props.CalendarState, this.props.CalendarOfficeID);
    if (this.cmpActivityTimelineRef.current !== undefined && this.cmpActivityTimelineRef.current != null)
      this.cmpActivityTimelineRef.current.onDiaryItemSubmit();
  };

  onAddDiaryItem = (e) => {
    if (this.props.onOpenDairyItemFunc !== undefined) this.props.onOpenDairyItemFunc(null);
  };

  onUpdateDiaryItem = (selected) => {
    //console.log("inside onUpdateDiaryItem", selected);

    var IsRange = false;
    if (selected.endDate != "") IsRange = true;

    var item = {
      diaryID: selected.diaryID,
      userID: selected.userID,
      officeID: selected.officeID,
      IsRange: IsRange,
      Type: selected.typeID,
      // FromDate: selected.startDate,
      // ToDate: selected.endDate,
      startDate: selected.startDate,
      endDate: selected.endDate,
      description: selected.description,
      files: selected.fileResults.slice(),
      contactID: selected.contactID,
      contactName: selected.contactName,
      propertyID: selected.propertyID,
      propertyName: selected.propertyName,
      leadID: selected.leadID,
      leadTitle: selected.leadTitle,
      leadUserID: selected.leadUserID,
    };

    if (selected != null) {
      if (this.props.onOpenDairyItemFunc !== undefined) this.props.onOpenDairyItemFunc(item);
    }
  };

  onDeleteDiaryItem = () => {
    var selected = null;
    for (var i = 0; i < this.state.data.length; i++) {
      if (this.state.data[i].diaryID == this.state.diaryId) {
        selected = this.state.data[i];
      }
    }

    /*  var data = new FormData();
          data.append('ID', selected.diaryID);
          data.append('userID', selected.userID); 
          data.append('officeID', selected.officeID);
          */
    //this.setState({ error_message: '', processing_save: true });
    /* var xhr = new XMLHttpRequest();
          xhr.open('post', '/Diary/DeleteDiaryAjax', true);
          xhr.onload = function () {
              var data = JSON.parse(xhr.responseText);
              if (data.status == "success") {
                  this.onDiaryItemSubmit();
              }
              else {
                  bootbox.alert(data.message);
                  //this.setState({ error_message: data.message, processing_save: false });
              }
          }.bind(this);
          xhr.send(data); */
    apiAgent.Diary.deleteDiaryAjax(selected.diaryID, selected.userID)
      .then((response) => {
        this.onDiaryItemSubmit();
      })
      // .catch((error) => bootbox.alert(error.response.message));
      .catch((error) => toast.info(error.response.message));
  };

  onActionDiaryItemFunc = (diaryID, IsDelete) => {
    //console.log("onActionDiaryItemFunc", diaryID, IsDelete);
    var selected = null;
    for (var i = 0; i < this.state.data.length; i++) {
      if (this.state.data[i].diaryID == diaryID) {
        selected = this.state.data[i];
      }
    }

    if (selected != null) this.onActionDiaryItemFunc2(selected, IsDelete);
  };

  onActionDiaryItemFunc2 = (DiaryItem, IsDelete) => {
    //console.log("onActionDiaryItemFunc2", DiaryItem, IsDelete);
    if (!IsDelete) {
      this.onUpdateDiaryItem(DiaryItem);
      return;
    }

    if (DiaryItem.diaryID) {
      /* bootbox.confirm({
          title: "Delete diary item?",
          message: "Do you want to delete diary item now? This cannot be undone.",
          buttons: {
            cancel: {
              label: '<i class="fa fa-times"></i> Cancel',
            },
            confirm: {
              label: '<i class="fa fa-check"></i> Confirm',
            },
          },
          callback: function (result) {
            if (result) {
              this.onDeleteDiaryItem(DiaryItem.diaryID);
            }
          }.bind(this),
        }); */
      this.setState({ diaryId: DiaryItem.diaryID });
      let title = "Delete diary item?";
      let body = "Do you want to delete diary item now? This cannot be undone.";
      let callback = this.onDeleteDiaryItem;
      this.showPopupAndDeleteOnConfirmation(title, body, callback);
    }
  };

  getSelectedDate = () => {
    return this.state.selected_date;
  };

  selectEvents = (selectedTags) => {
    this.setState({ ...this.state, selectedEvents: selectedTags });
  };

  togglePinNote = (noteItem) => {
    if (this.props.togglePinNote !== undefined) {
      this.props.togglePinNote(noteItem);
    }
  };

  renderHorizontal = (selectedDates, selectedItems, upcomingEvents) => {
    return (
      <div>
        <div className="col-md-12 col-sm-12 d-flex align-items-center" style={{ marginBottom: 20 }}>
          <div className="d-flex" style={{ flex: 1 }}>
            <div className="d-flex radio-group">
              <button
                id="viewTypeCalender"
                className={`view-type-radio ${this.state.view_type === "calender" ? "radio-selected" : ""}`}
                onClick={
                  (e) => {
                  this.setState({ ...this.state, view_type: "calender" });
                  e.preventDefault();
                  }
              
              }

              >
                <i className="fa fa-calendar view-type-icon"></i>
              </button>
              <label className="view-type-label" htmlFor="viewTypeCalender">
                Activity Calender
              </label>
            </div>
            <div className="d-flex radio-group">
              <button
                id="viewTypeTimeline"
                className={`view-type-radio ${this.state.view_type === "timeline" ? "radio-selected" : ""}`}
                onClick={
                  (e) => {
                  this.setState({ ...this.state, view_type: "timeline" });
                  e.preventDefault();
                  }
                }                
              >
                <i className="fa fa-list view-type-icon"></i>
              </button>
              <label className="view-type-label" htmlFor="viewTypeTimeline">
                Activity Timeline
              </label>
            </div>
            {this.state.view_type === "timeline" && (
              <div className="horizontal-filter" style={{ marginTop: 35, marginLeft: -345, marginRight: 340 }}>
                <ActivityFilter DiaryActionTags={this.props.DiaryActionTags} DNType={this.props.DNType} ID={this.props.ID} selectEvents={this.selectEvents} />
              </div>
            )}
            {this.state.view_type === "timeline" && this.props.ShowAdd && !this.props.ReadOnly && (
              <div className="pull-right">
                <button type="button" className="btn btn-primary" onClick={this.onAddDiaryItem}>
                  Add
                </button>
              </div>
            )}
          </div>
        </div>
        {this.state.view_type === "timeline" && (
          <div className="vertical-filter">
            <ActivityFilter DiaryActionTags={this.props.DiaryActionTags} DNType={this.props.DNType} ID={this.props.ID} selectEvents={this.selectEvents} />
          </div>
        )}
        {this.state.view_type === "timeline" ? (
          <OMSActivityTimeline
            ref={this.cmpActivityTimelineRef}
            onActionFunc={this.onActionDiaryItemFunc2}
            togglePinNote={this.togglePinNote}
            ReadOnly={this.props.ReadOnly}
            DNType={this.props.DNType}
            ID={this.props.ID}
            selectedEvents={this.state.selectedEvents}
          />
        ) : (
          <div>
            <div className="col-md-6 col-sm-12">
              <div className="form-group">
                <OMSCalendar id="contactCalander" data={selectedDates} onChange={this.onCalendarChange} />
              </div>
              {this.props.ShowAdd && this.props.ReadOnly == false ? (
                <div className="form-group col-md-12">
                  <div className="pull-right">
                    <button type="button" className="btn btn-primary" onClick={this.onAddDiaryItem}>
                      Add Event
                    </button>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="col-md-6 col-sm-12">
              {this.state.loading != true ? (
                <div>
                  {/*this.props.DNType == 0 ? <div className="form-group">
                                              {selectedItems.length == 0 ?
                                              (upcomingEvents.length > 0 ? <label>upcoming events</label> : <label>no entries for this day</label>) :
                                              <label>diary view for {this.state.selected_date_title}</label>
                                              }
                                              </div> : null */}
                  <div className="form-group">
                    {selectedItems.length == 0 ? (
                      upcomingEvents.length > 0 ? (
                        <label>upcoming events</label>
                      ) : (
                        <label>no entries for this day</label>
                      )
                    ) : (
                      <label>diary view for {this.state.selected_date_title}</label>
                    )}
                  </div>

                  {selectedItems.length == 0
                    ? upcomingEvents.map((item) => (
                        <OMSDiaryNoteItemDisplay
                          ReadOnly={this.props.ReadOnly}
                          key={"DR" + item.diaryID}
                          IsUpcomingEvent={true}
                          DNType={this.props.DNType}
                          Item={item}
                          onActionFunc={this.onActionDiaryItemFunc}
                        />
                      ))
                    : selectedItems.map((item) => (
                        <OMSDiaryNoteItemDisplay
                          ReadOnly={this.props.ReadOnly}
                          key={"DR" + item.diaryID}
                          IsUpcomingEvent={false}
                          DNType={this.props.DNType}
                          Item={item}
                          onActionFunc={this.onActionDiaryItemFunc}
                        />
                      ))}
                </div>
              ) : (
                <div className="form-group">
                  <DataTableLoading loading={this.state.loading} />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  renderVerticle = (selectedDates, selectedItems, upcomingEvents) => {
    return (
      <div>
        <div className="form-group">
          <OMSCalendar id="contactCalander" data={selectedDates} onChange={this.onCalendarChange} />
        </div>
        <div className="form-group col-md-12">
          <div className="pull-right">
            {this.props.ShowAdd && this.props.ReadOnly == false ? (
              <button type="button" className="btn btn-primary" onClick={this.onAddDiaryItem}>
                Add
              </button>
            ) : null}
          </div>
        </div>

        {this.state.loading != true ? (
          <div>
            <div className="form-group">
              {selectedItems.length == 0 ? (
                upcomingEvents.length > 0 ? (
                  <label>upcoming events</label>
                ) : (
                  <label>no entries for this day</label>
                )
              ) : (
                <label>diary view for {this.state.selected_date_title}</label>
              )}
            </div>
            {selectedItems.length == 0
              ? upcomingEvents.map((item) => (
                  <OMSDiaryNoteItemDisplay
                    ReadOnly={this.props.ReadOnly}
                    key={"DR" + item.diaryID}
                    IsUpcomingEvent={true}
                    DNType={this.props.DNType}
                    Item={item}
                    onActionFunc={this.onActionDiaryItemFunc}
                  />
                ))
              : selectedItems.map((item) => (
                  <OMSDiaryNoteItemDisplay
                    ReadOnly={this.props.ReadOnly}
                    key={"DR" + item.diaryID}
                    IsUpcomingEvent={false}
                    DNType={this.props.DNType}
                    Item={item}
                    onActionFunc={this.onActionDiaryItemFunc}
                  />
                ))}
          </div>
        ) : (
          <div className="form-group">
            <DataTableLoading loading={this.state.loading} />
          </div>
        )}
      </div>
    );
  };

  render() {
    var upcomingEvents = [];
    var selectedDates = this.state.data.filter((I) => I.isUpcoming == false).map((I) => I.date);
    var selectedItems = this.state.data.filter((I) => I.date == this.state.selected_date.getDate() && I.isUpcoming == false);
    if (selectedItems.length == 0) {
      upcomingEvents = this.state.data.filter((I) => I.date > this.state.selected_date.getDate() && I.isUpcoming == false);
      if (upcomingEvents.length < 3) {
        var upcomingEvents2 = this.state.data.filter((I) => I.isUpcoming == true);
        if (upcomingEvents2.length > 0) upcomingEvents = upcomingEvents.concat(upcomingEvents2);
      }
      if (upcomingEvents.length > 3) upcomingEvents = upcomingEvents.slice(0, 3);
    }
    return this.props.Display == "V"
      ? this.renderVerticle(selectedDates, selectedItems, upcomingEvents)
      : this.renderHorizontal(selectedDates, selectedItems, upcomingEvents);
  }
}
