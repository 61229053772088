import React from "react";
import { toast } from "react-toastify";
import { OMSDropdown } from "../../app/common/common-components/OMSDropdown";
import { OMSButtonStyleL } from "../../app/common/common-components/OMSButton";
import { OMSLead } from "../../app/common/common-components/OMSInboxComponents/OMSLead";
import { OMSSendMessage } from "../../app/common/common-components/OMSSendMessage";
import apiAgent from "../../app/api/apiAgent";
import { OMSDiaryNoteCalendar } from "../../app/common/common-components/OMSDiaryNoteComponents/OMSDiaryNoteCalendar";
import { OMSDiaryNoteItem } from "../../app/common/common-components/OMSDiaryNoteComponents/OMSDiaryNoteItem";
import { withRouter } from "react-router-dom";
import defaultUserIcon from "../../assets/images/img-default-user.png";
import { Link } from "react-router-dom";
import defaultImage from "../../assets/images/img-upload-default.png";

class Dashboard extends React.Component {
  state = {
    Data: this.props.dashboardData.data,
    UnreadCount: this.props.dashboardData.unreadCount,
    Active: this.props.dashboardData.active,
    Pending: this.props.dashboardData.pending,
    Errors: this.props.dashboardData.error,
    ForSale: this.props.dashboardData.forSale,
    ForRent: this.props.dashboardData.forRent,
    ForRentAmount: this.props.dashboardData.forRentAmount,
    ForSaleAmount: this.props.dashboardData.forSaleAmount,
    ForSaleSymbol: this.props.dashboardData.forSaleSymbol,
    ForRentSymbol: this.props.dashboardData.forRentSymbol,
    view: this.props.dashboardData.showNoticeboard == true ? 5 : 1,
    status_description: "Requesting status..",
    status_color: "grey",
    KPI_view: "1",
    SHOW_ITEMS: 20,
    IsNoticeLoaded: false,
    LastUpdated: "",
  };

  constructor(props) {
    super(props);

    this.myLeadBoxRef = React.createRef();
    this.sendMessageRef = React.createRef();
    this.noteItemRef = React.createRef();
    this.noteCalenderRef = React.createRef();
  }



  // componentDidMount(){
  //   this.setState({view: 1});
  // }

  onLeadItemClick = (userID, ItemID, IsRead, Title) => {
    this.myLeadBoxRef.current.onInboxClick(userID, ItemID, IsRead, Title);
  };

  onMessageItemClick = (userID, ParentMessageID) => {
    this.onMessageItemClickFull(userID, 1, ParentMessageID, "", "");
  };

  onMessageItemClickFull = (userID, Type, ParentMessageID, contactID, contactName) => {
    this.sendMessageRef.current.onOpen(userID, Type, ParentMessageID, contactID, contactName);
  };

  onSendMessageSuccess = (e) => {
    this.RefreshDashboardItems();
  };

  onPropertyItemClick = (PropertyID) => {
    //var a = document.createElement("a");
    //a.target = "_blank";
    //a.href = "/Property/SaveProperty?ID=" + PropertyID;
    //a.click();
    this.props.history.push(`/Property/SaveProperty?ID=${PropertyID}`);
  };

  onDiaryItemClick = (selected) => {
    console.log("inside  onDiaryItemClick, selected=", selected);
    var IsRange = false;
    if (selected.EndDate != "") IsRange = true;

    var item = {
      diaryID: selected.DiaryID,
      userID: selected.UserID,
      OfficeID: selected.OfficeID,
      IsRange: IsRange,
      Type: selected.TypeID,
      startDate: selected.StartDate,
      endDate: selected.EndDate,
      description: selected.Description,
      files: selected.FileResults ? selected.FileResults.slice() : [],
      contactID: selected.ContactID,
      contactName: selected.ContactName,
      propertyID: selected.PropertyID,
      propertyName: selected.PropertyName,
      leadID: selected.LeadID,
      leadUserID: selected.LeadUserID,
      leadTitle: selected.LeadTitle,
    };

    this.noteItemRef.current.onOpenDairyNoteItem(item);
  };

  onDocumentItemClick = (FileID) => {
    this.props.history.push(`/Library/FSDownloadFileAjax?FileID=${FileID}`);
  };

  onLoadMoreClick = (factor) => {
    var showItems = this.state.SHOW_ITEMS + factor * 20;
    this.setState({ SHOW_ITEMS: showItems });
  };

  RefreshDashboardItems = () => {
    if (this.state.view == 3) {
      this.myLeadBoxRef.current.RefreshInbox();
    }
  };

  onChangeView = (view) => {
    this.setState({ view: view }, function () {
      this.RefreshDashboardItems();
    });
  };

  onOpenDairyItemFunc = (item) => {
    if (item == null) {
      this.noteItemRef.current.onOpenDairyNoteItem(item, this.state.CalendarUserID, this.noteCalenderRef.current.getSelectedDate());
    } else {
      this.noteItemRef.current.onOpenDairyNoteItem(item, this.state.CalendarUserID);
    }
  };

  onDiaryItemSubmitCompleteFunc = (e) => {
    this.RefreshDashboardItems();
    this.noteCalenderRef.current.onDiaryItemSubmit();
  };

  // getSystemStatus = () => {
  //     var sp = new StatusPage.page({ page: '4d7f8ndpjxtc' });
  //     sp.summary({
  //         success: function (data) {
  //             var status_color = "grey"
  //             if (data.status.indicator == "none")
  //                 status_color = "green";
  //             this.setState({ status_description: data.status.description, status_color: status_color });
  //         }.bind(this)
  //     });
  // };

  handleCalendarViewDropdownChange = (e) => {
    if (e.newText.toLowerCase() == "office calendar") {
      this.setState({ CalendarUserIDValue: e.newValue, CalendarUserID: "", CalendarState: e.newText, CalendarOfficeID: e.newValue });
    } else {
      this.setState({ CalendarUserIDValue: e.newValue, CalendarUserID: e.newValue, CalendarState: "", CalendarOfficeID: "" });
    }
  };

  handleKPIViewChange = (e) => {
    this.setState({ KPI_view: e.target.value });
  };

  onSnoozClick = (ObjectID, typeID, itemIndex) => {
    //this.setState({ ButtonStatus: { disabled: true, processing_save: false, processing_delete: true } });
    /* var data = new FormData();
        data.append('ObjectID', ObjectID);
        data.append('typeID', typeID);
        var xhr = new XMLHttpRequest();
        xhr.open('post', '/Home/SetSnoozAjax', true);
        xhr.onload = function () {
            var data = JSON.parse(xhr.responseText);
            if (data.status == "success") {
                var data = this.state.Data;
                var index = -1;
                for (var i = 0; i < data.length; i++) {
                    if (data[i].ObjectID == ObjectID && data[i].typeID == typeID) {
                        index = i;
                        break;
                    }
                }
                if (index != -1) {
                    data.splice(index, 1);
                    this.setState({ Data: data });
                }
            }
            else {
                toast.info(data.message);
                //this.setState({ ButtonStatus: { disabled: false, processing_save: false, processing_delete: false } });
            }
        }.bind(this);
        xhr.send(data); */
    apiAgent.Home.setSnoozAjax(ObjectID, typeID)
      .then((res) => {
        //console.log("result from setSnoozAjax api call: ", data);
        var data = this.state.Data;
        // var index = -1;
        // for (var i = 0; i < data.length; i++) {
        //   if (data[i].ObjectID == ObjectID && data[i].typeID == typeID) {
        //     index = i;
        //     break;
        //   }
        // }
        // if (index != -1) {
        //   data.splice(index, 1);
        //   this.setState({ Data: data });
        // }
        if (res.status == "success") {
          data.splice(itemIndex, 1);
          this.setState({ Data: data });
        }
      })
      .catch((error) => {
        toast.info(error.data.message);
        //this.setState({ ButtonStatus: { disabled: false, processing_save: false, processing_delete: false } });
      });
  };

  renderItem = (item, index) => {
    var data = JSON.parse(item.jsonString);

    if (item.typeID == 1 || item.typeID == 5) {
      // new property

      var CurrencyDisplay = "R";
      if (data.Currency) CurrencyDisplay = data.Currency;
      else if (this.props.dashboardData.currencyDisplay) CurrencyDisplay = this.props.dashboardData.currencyDisplay;

      return (
        <div key={"P-" + index + "-" + item.objectID} className="media media-custom-grey">
          <div className="media-left media-top">
            <img className="display-img img-circle" src={item.userProfilePic == "" ? defaultUserIcon : item.userProfilePic} alt="" />
          </div>
          <div className="media-body">
            <span className="media-heading">
              <b>{item.userFullname}</b>
              {item.typeID == 1
                ? " added a new listing mandate"
                : " updated a listing mandate (price from " + CurrencyDisplay + data.OldPrice + " to " + CurrencyDisplay + data.Price + ")"}{" "}
              <span className="time-span">({item.duration})</span>
            </span>
            <div className="media media-listing hidden-sm hidden-xs">
              <div className="media-left">
                <div
                  className="search-img"
                  onClick={() => this.onPropertyItemClick(item.objectID)}
                  style={{
                    cursor: "pointer",
                    backgroundImage: "url(" + (data.PhotoUrl == "" ? defaultImage : data.PhotoUrl) + ")",
                  }}
                >
                  <span className="price color2">
                    {CurrencyDisplay} {data.Price}
                  </span>
                </div>
              </div>
              <div className="media-body">
                <div className="media-heading">
                  <div className="ellipsis-container">
                    <div className="ellipsis-div">
                      <h4 style={{ cursor: "pointer", margin: 0 }} onClick={() => this.onPropertyItemClick(item.objectID)}>
                        {data.Title}
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="listing-info">
                  <div className="ellipsis-container">
                    <div className="ellipsis-div">
                      <b>{data.Headline}</b>
                    </div>
                  </div>
                  <div className="ellipsis-container" style={{ marginTop: 5 }}>
                    <div className="ellipsis-div">{data.Description}</div>
                  </div>
                </div>
                <div className="icon-info">
                  {data.Bedrooms && data.Bedrooms != "-" ? (
                    <span className="icon-span">
                      <i className="fa fa-bed"></i> {data.Bedrooms} beds
                    </span>
                  ) : null}
                  {data.Bathrooms && data.Bathrooms != "-" ? (
                    <span className="icon-span">
                      <i className="fa fa-bath"></i> {data.Bathrooms} baths
                    </span>
                  ) : null}
                  {data.BuildingSize && data.BuildingSize != "-" ? (
                    <span className="icon-span">
                      <i className="fa fa-bank"></i> {data.BuildingSize}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="media media-listing hidden-md hidden-lg" style={{ height: "auto", maxHeight: 500, margin: 5 }}>
              <div className="media-middle">
                <div
                  className="search-img"
                  onClick={() => this.onPropertyItemClick(item.objectID)}
                  style={{
                    cursor: "pointer",
                    minWidth: 200,
                    backgroundImage: "url(" + (data.PhotoUrl == "" ? defaultImage : data.PhotoUrl) + ")",
                  }}
                >
                  <span className="price color2">
                    {CurrencyDisplay} {data.Price}
                  </span>
                </div>
              </div>
              <div className="media-body">
                <div className="media-heading">
                  <div className="ellipsis-container">
                    <div className="ellipsis-div">
                      <span
                        style={{
                          cursor: "pointer",
                          margin: 0,
                          fontSize: 18,
                          fontWeight: 500,
                        }}
                        onClick={() => this.onPropertyItemClick(item.objectID)}
                      >
                        {data.Title}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="listing-info">
                  <div className="ellipsis-container">
                    <div className="ellipsis-div">
                      <b>{data.Headline}</b>
                    </div>
                  </div>
                  <div className="ellipsis-container" style={{ marginTop: 5 }}>
                    <div className="ellipsis-div">{data.Description}</div>
                  </div>
                </div>
                <div className="icon-info">
                  {data.Bedrooms && data.Bedrooms != "-" ? (
                    <span className="icon-span">
                      <i className="fa fa-bed"></i> {data.Bedrooms}{" "}
                    </span>
                  ) : null}
                  {data.Bathrooms && data.Bathrooms != "-" ? (
                    <span className="icon-span">
                      <i className="fa fa-bath"></i> {data.Bathrooms}{" "}
                    </span>
                  ) : null}
                  {data.BuildingSize && data.BuildingSize != "-" ? (
                    <span className="icon-span">
                      <i className="fa fa-bank"></i> {data.BuildingSize}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (item.typeID == 4) {
      // new diary
      //var data = JSON.parse(item.jsonString);
      var displayString = "";
      if (data.ContactID != "") displayString = data.ContactName;

      if (data.PropertyID != "") {
        if (displayString == "") displayString = data.PropertyName;
        else displayString = displayString + " | " + data.PropertyName;
      }

      if (data.Description != "") {
        var endTrim = data.Description.length > 60 ? ".." : "";

        if (displayString == "") {
          displayString = data.Description.substring(0, 60) + endTrim;
        } else {
          displayString = displayString + ": " + data.Description.substring(0, 60) + endTrim;
        }
      }

      var timeString = data.StartDate.substring(10);
      return (
        <div key={"D-" + index + "-" + item.objectID} className="media media-custom">
          <div className="media-left media-middle">
            <span className="display-icon">
              <i className="fa fa-calendar"></i>
            </span>
          </div>
          <div className="media-body media-middle">
            <span>
              <b onClick={() => this.onDiaryItemClick(data)} style={{ cursor: "pointer" }}>
                {data.TypeID} {item.duration == "future" ? "tomorrow" : "today"} {timeString}:{" "}
              </b>
              {displayString} <span className="time-span">{item.duration == "" || item.duration == "future" ? "" : "(" + item.duration + ")"}</span>
            </span>
          </div>
        </div>
      );
    } else if (item.typeID == 3) {
      // new lead
      //var data = JSON.parse(item.jsonString);
      if (data.Type == 4) {
        // message type
        return (
          <div style={{ borderColor: "#DC5C42" }} key={"L-" + index + "-" + item.objectID} className="media media-custom">
            <div className="media-left media-middle">
              <span className="display-icon">
                <i className="fa fa-commenting"></i>
              </span>
            </div>
            <div className="media-body media-middle">
              <span>
                <b>SMS</b> from {data.Source}: {data.Title} <span className="time-span">({item.duration})</span>
              </span>
            </div>
            <div className="media-right media-middle">
              <a
                href="javascript:void(0)"
                onClick={() => this.onMessageItemClick(item.userID, data.ParentItemID)}
                className="btn btn-oms-success"
                style={{
                  backgroundColor: "#DC5C42",
                  color: "white",
                  cursor: "pointer",
                }}
              >
                Action
              </a>
            </div>
          </div>
        );
      } // lead type
      else {
        return (
          <div key={"L-" + index + "-" + item.objectID} className="media media-custom">
            <div className="media-left media-middle">
              <span className="display-icon">
                <i className="fa fa-id-card-o"></i>
              </span>
            </div>
            <div className="media-body media-middle">
              <span>
                <b>{data.Title} </b>from {data.Source} <span className="time-span">({item.duration})</span>
              </span>
            </div>
            <div className="media-right media-middle">
              <a
                href="javascript:void(0)"
                onClick={() => this.onLeadItemClick(data.UserID, data.ItemID, data.IsRead, data.Title)}
                className="btn btn-oms-success"
                style={{
                  backgroundColor: "red",
                  color: "white",
                  cursor: "pointer",
                }}
              >
                Action
              </a>
            </div>
          </div>
        );
      }
    } else if (item.typeID == 7) {
      // new message
      //var data = JSON.parse(item.jsonString);
      return (
        <div style={{ borderColor: "#DC5C42" }} key={"LM-" + index + "-" + item.objectID} className="media media-custom">
          <div className="media-left media-middle">
            <span className="display-icon">
              <i className={data.Type == 1 ? "fa fa-commenting" : data.Type == 2 ? "fa fa-whatsapp" : "fa fa-envelope"}></i>
            </span>
          </div>
          <div className="media-body media-middle">
            <span>
              <b>{data.Type == 1 ? "SMS" : data.Type == 2 ? "Whatsapp" : "Email"}</b> from {data.ContactName}: {data.Message}{" "}
              <span className="time-span">({item.duration})</span>
            </span>
          </div>
          <div className="media-right media-middle">
            <a
              href="javascript:void(0)"
              onClick={() => this.onMessageItemClickFull(item.userID, data.Type, data.ParentMessageID, data.ContactID, data.ContactName)}
              className="btn btn-oms-success"
              style={{
                backgroundColor: "#DC5C42",
                color: "white",
                cursor: "pointer",
              }}
            >
              Action
            </a>
          </div>
        </div>
      );
    } else if (item.typeID == 6) {
      // listing expiring soon.
      //var data = JSON.parse(item.jsonString);
      return (
        <div style={{ borderColor: "#C70039" }} key={"LX-" + index + "-" + item.objectID} className="media media-custom">
          <div className="media-left media-middle">
            <span className="display-icon" style={{ color: "#C70039" }}>
              <i className="fa fa-exclamation-circle"></i>
            </span>
          </div>
          <div className="media-body media-middle">
            <span>
              <b style={{ color: "#C70039" }}>Listing </b>#{data.WebRef} ({data.DisplayName}) mandate will be expiring within {item.duration}
            </span>
          </div>
          <div className="media-right media-middle">
            <div style={{ display: "flex" }}>
              {/* <a
                href={"/Property/SaveProperty?ID=" + item.objectID}
                className="btn btn-oms-success"
                style={{
                  backgroundColor: "#C70039",
                  color: "white",
                  cursor: "pointer",
                }}
              >
                Action
              </a> */}
              <Link
                to={`/Property/SaveProperty?ID=${item.objectID}`}
                className="btn btn-oms-success"
                style={{
                  backgroundColor: "#C70039",
                  color: "white",
                  cursor: "pointer",
                }}
              >
                Action
              </Link>
              <span className="span-snooz-icon" title="Snooze alert" onClick={() => this.onSnoozClick(item.objectID, item.typeID, index)}>
                <i className="fa fa-close"></i>
              </span>
            </div>
          </div>
        </div>
      );
    } else if (item.typeID == 8) {
      // listing active so long.

      //var data = JSON.parse(item.jsonString);

      return (
        <div style={{ borderColor: "#FF5733" }} key={"LAL-" + index + "-" + item.objectID} className="media media-custom">
          <div className="media-left media-middle">
            <span className="display-icon" style={{ color: "#FF5733" }}>
              <i className="fa fa-exclamation-circle"></i>
            </span>
          </div>
          <div className="media-body media-middle">
            <span>
              <b style={{ color: "#FF5733" }}>Listing </b>#{data.WebRef} ({data.DisplayName}) has been active for {item.duration}
            </span>
          </div>
          <div className="media-right media-middle">
            <div style={{ display: "flex" }}>
              {/* <a
                href={"/Property/SaveProperty?ID=" + item.objectID}
                className="btn btn-oms-success"
                style={{
                  backgroundColor: "#FF5733",
                  color: "white",
                  cursor: "pointer",
                }}
              >
                Action
              </a> */}
              <Link
                to={`/Property/SaveProperty?ID=${item.objectID}`}
                className="btn btn-oms-success"
                style={{
                  backgroundColor: "#FF5733",
                  color: "white",
                  cursor: "pointer",
                }}
              >
                Action
              </Link>
              <span className="span-snooz-icon" title="Snooze alert" onClick={() => this.onSnoozClick(item.objectID, item.typeID, index)}>
                <i className="fa fa-close"></i>
              </span>
            </div>
          </div>
        </div>
      );
    } else if (item.typeID == 2) {
      //var data = JSON.parse(item.jsonString);

      var fileicon = "fa-file-o";
      if (data.Name.match(/\.(jpg|jpeg|png|gif)$/)) {
        fileicon = "fa-file-image-o";
      } else if (data.Name.match(/\.(pdf)$/)) fileicon = "fa-file-pdf-o";
      else if (data.Name.match(/\.(txt)$/)) fileicon = "fa-file-text-o";
      else if (data.Name.match(/\.(zip|rar)$/)) fileicon = "fa-file-zip-o";
      else if (data.Name.match(/\.(csv|xls|xlsx)$/)) fileicon = "fa-file-excel-o";
      else if (data.Name.match(/\.(ppt|pptx)$/)) fileicon = "fa-file-powerpoint-o";
      else if (data.Name.match(/\.(doc|docx)$/)) fileicon = "fa-file-word-o";
      else fileicon = "fa-file-o";

      return (
        <div key={"F-" + index + "-" + item.objectID} className="media media-custom-grey">
          <div className="media-left media-top">
            <img className="display-img img-circle" src={item.userProfilePic == "" ? defaultUserIcon : item.userProfilePic} alt="" />
          </div>
          <div className="media-body">
            <span className="media-heading">
              <b>{item.userFullname}</b> added a new office document <span className="time-span">({item.duration})</span>
            </span>
            <div className="document-info">
              <div className="ellipsis-container" style={{ marginTop: 5 }}>
                <div className="ellipsis-div">
                  <span onClick={() => this.onDocumentItemClick(data.FileID)} style={{ cursor: "pointer" }}>
                    <i className={"fa " + fileicon}></i>
                    {data.Title}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (item.typeID == 0) {
      // announcement
      //var data = JSON.parse(item.jsonString);
      return (
        <div style={{ borderColor: "#1DA1F2" }} key={"L-" + index + "-" + item.objectID} className="media media-custom">
          <div className="media-left media-middle">
            <span className="display-icon">
              <i style={{ color: "#1DA1F2" }} className="fa fa-twitter"></i>
            </span>
          </div>
          <div className="media-body media-middle">
            <span>
              <a target="_blank" href={"https://twitter.com/Entegral_Base/status/" + data.ID}>
                <b>Base update:</b>
              </a>{" "}
              <span dangerouslySetInnerHTML={{ __html: data.Text }}></span>
            </span>
          </div>
          {/*<div className="media-right media-middle">
                        <span className="time-span">({item.duration})</span>
            </div>*/}
        </div>
      );
    }
  };

  renderCircle = (icon, color, count, text, title) => {
    var opacity = 1;
    if (count == "" || count == "0") {
      color = "grey";
      opacity = 0.4;
    }
    return (
      <div className="circle" data-placement="bottom" data-toggle="tooltip" title={title} style={{ borderColor: color, opacity: opacity }}>
        <span className={"fa " + icon} style={{ color: color }}></span>
        <span className="number">{count}</span>
        <span>{text}</span>
      </div>
    );
  };

  render() {
    //console.log("inside render, this.state.Data", this.state.Data);
    var data = this.state.Data.slice(0, this.state.SHOW_ITEMS);
    var showMore = this.state.Data.length > this.state.SHOW_ITEMS;
    var CurrencyDisplay = "R";
    if (this.props.dashboardData.currencyDisplay) CurrencyDisplay = this.props.dashboardData.currencyDisplay;
    //var showLess = this.state.SHOW_ITEMS > 20;
    return (
      <div>
        <br />
        <div className="row">
          <div className="col-md-2 hidden-xs hidden-sm">
            <span className={"dashboard-menu"} style={{ cursor: "pointer" }} onClick={() => this.onChangeView("1")}>
              <i className="fa fa-gift" style={{ marginRight: 8 }}></i>
              {this.state.view == 1 ? <b>What's new</b> : <span>What's new</span>}
            </span>
            <br />
            <span className={"dashboard-menu"} style={{ cursor: "pointer" }} onClick={() => this.onChangeView("2")}>
              <i className="fa fa-calendar" style={{ marginRight: 8 }}></i>
              {this.state.view == 2 ? <b>Calendar</b> : <span>Calendar</span>}
            </span>
            <br />
            <span className={"dashboard-menu"} style={{ cursor: "pointer" }} onClick={() => this.onChangeView("3")}>
              <i className="fa fa-hand-stop-o" style={{ marginRight: 8 }}></i>
              {this.state.view == 3 ? (
                <b>Inbox {this.state.UnreadCount != 0 ? <span className={"badge"}>{this.state.UnreadCount}</span> : null}</b>
              ) : (
                <span>Inbox {this.state.UnreadCount != 0 ? <span className={"badge"}>{this.state.UnreadCount}</span> : null}</span>
              )}
            </span>
            {/*}
                        <br />
                        {this.props.dashboardData.IsSuperAdmin ? <span className="dashboard-menu" style={{ cursor: 'pointer' }} onClick={() => this.onChangeView('4')}><i className="fa fa-pie-chart" style={{ marginRight: 8 }}></i>{this.state.view == 4 ? <b>KPI</b> : <span>KPI</span>}</span> : null}
                        */}
            <br />
            {this.props.dashboardData.isPreApproval && (
              <>
                {/* <a href={this.props.dashboardData.preApprovalLink} className={"dashboard-menu"} target="_blank" style={{ cursor: "pointer" ,color:"black"}} >
                  <i className={`fa ${this.props.dashboardData.preApprovalIcon}`} style={{ marginRight: 8 }}></i>
                  <span>{this.props.dashboardData.preApprovalText}</span>
            </a> */}
                <Link to={this.props.dashboardData.preApprovalLink} target="_blank" className={"dashboard-menu"} style={{ cursor: "pointer", color: "black" }}>
                  <i className={`fa ${this.props.dashboardData.preApprovalIcon}`} style={{ marginRight: 8 }}></i>
                  <span>{this.props.dashboardData.preApprovalText}</span>
                </Link>
                <br />
              </>
            )}
            <span className={"dashboard-menu"} style={{ cursor: "pointer" }} onClick={() => this.onChangeView("5")}>
              <i className="fa fa-bullhorn" style={{ marginRight: 8 }}></i>
              {this.state.view == 5 ? <b>Notice board</b> : <span>Notice board</span>}
            </span>
            <br />
          </div>
          <div className="col-sm-12 hidden-md hidden-lg">
            <div style={{ margin: 10, padding: 5 }}>
              <div className="row" data-intercom-target="dash-stats1">
                <div className="col-xs-6 text-center">
                  <div
                    className="panel panel-default oms-box"
                    style={{
                      background: "transparent",
                      padding: 5,
                      maxWidth: 135,
                      float: "right",
                    }}
                  >
                    <div className="panel-body">
                      <Link to="/Property?dashboardSectionClicked=forSale" style={{ textDecoration: "none", color: "inherit" }}>
                        <span style={{ fontSize: 35 }}>
                          <b>{this.state.ForSale}</b>
                        </span>{" "}
                        <br />
                        <span>For Sale</span> <br />
                        <span
                          data-placement="bottom"
                          data-toggle="tooltip"
                          title="Total price for active for sale listings"
                          style={{ display: "inline-block", marginTop: 5 }}
                        >
                          <i style={{ fontSize: 14 }} className="fa fa-globe"></i> {CurrencyDisplay}
                          {this.state.ForSaleAmount}
                          {this.state.ForSaleSymbol}
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-xs-6 text-center">
                  <div
                    className="panel panel-default oms-box"
                    style={{
                      background: "transparent",
                      padding: 5,
                      maxWidth: 135,
                      float: "left",
                    }}
                  >
                    <div className="panel-body">
                      <Link to="/Property?dashboardSectionClicked=forRent" style={{ textDecoration: "none", color: "inherit" }}>
                        <span style={{ fontSize: 35 }}>
                          <b>{this.state.ForRent}</b>
                        </span>{" "}
                        <br />
                        <span>For Rent</span> <br />
                        <span
                          data-placement="bottom"
                          data-toggle="tooltip"
                          title="Total price for active for rent listings"
                          style={{ display: "inline-block", marginTop: 5 }}
                        >
                          <i style={{ fontSize: 14 }} className="fa fa-globe"></i> {CurrencyDisplay}
                          {this.state.ForRentAmount}
                          {this.state.ForRentSymbol}
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ margin: 10, padding: 5 }}>
              <div className="row" data-intercom-target="dash-stats2">
                <div className="col-xs-4">
                  <Link to="/Property?dashboardSectionClicked=syndication" style={{ textDecoration: "none", color: "inherit" }}>
                    {this.renderCircle("fa-check-circle", "green", this.state.Active, "Active", "Feed status: number of listings active")}
                  </Link>
                </div>
                <div className="col-xs-4 text-center">
                  <Link to="/Property?dashboardSectionClicked=syndication" style={{ textDecoration: "none", color: "inherit" }}>
                    {this.renderCircle("fa-clock-o", "orange", this.state.Pending, "Pending", "Feed status: number of listings pending")}
                  </Link>
                </div>
                <div className="col-xs-4 text-center">
                  <Link to="/Property?dashboardSectionClicked=syndication" style={{ textDecoration: "none", color: "inherit" }}>
                    {this.renderCircle("fa-exclamation-triangle", "red", this.state.Errors, "Errors", "Feed status: number of listings errors")}
                  </Link>
                </div>
              </div>
            </div>
            {/*<div className="row">
                            <div className="col-xs-12 text-center" style={{ marginBottom: 10, marginTop: 5 }}>
                                <p style={{ marginTop: 10 }}><i className="fa fa-bell-o" style={{ fontSize: 20, marginRight: 10 }} aria-hidden="true"></i><i className="fa fa-circle" style={{ fontSize: 14, marginRight: 5, color: this.state.status_color }} aria-hidden="true"></i><a href="http://status.entegral.net/" target="_blank" style={{ cursor: 'pointer' }}>{this.state.status_description}</a></p>
                            </div>
                    </div>*/}
          </div>
          <div className="col-sm-12 hidden-md hidden-lg" style={{ marginBottom: 10 }}>
            <span className="dashboard-menu" style={{ cursor: "pointer", marginRight: 10 }} onClick={() => this.onChangeView("1")}>
              <i className="fa fa-gift" style={{ marginRight: 8 }}></i>
              {this.state.view == 1 ? <b>What's new</b> : <span>What's new</span>}
            </span>
            <span className="dashboard-menu" style={{ cursor: "pointer", marginRight: 10 }} onClick={() => this.onChangeView("2")}>
              <i className="fa fa-calendar" style={{ marginRight: 8 }}></i>
              {this.state.view == 2 ? <b>Calendar</b> : <span>Calendar</span>}
            </span>
            <span className="dashboard-menu" style={{ cursor: "pointer" }} onClick={() => this.onChangeView("3")}>
              <i className="fa fa-hand-stop-o" style={{ marginRight: 8 }}></i>
              {this.state.view == 3 ? (
                <b>Inbox {this.state.UnreadCount != 0 ? <span className="badge">{this.state.UnreadCount}</span> : null}</b>
              ) : (
                <span>Inbox {this.state.UnreadCount != 0 ? <span className="badge">{this.state.UnreadCount}</span> : null}</span>
              )}
            </span>
            {/*this.props.dashboardData.IsSuperAdmin ? <span className="dashboard-menu" style={{ cursor: 'pointer', marginRight: 10 }} onClick={() => this.onChangeView('4')}><i className="fa fa-bullhorn" style={{ marginLeft: 8 }}></i>{this.state.view == 4 ? <b>KPI</b> : <span>KPI</span>}</span> : null*/}
            <span className="dashboard-menu" style={{ cursor: "pointer", marginRight: 10 }} onClick={() => this.onChangeView("5")}>
              <i className="fa fa-bullhorn" style={{ marginRight: 8 }}></i>
              {this.state.view == 5 ? <b>Notice board</b> : <span>Notice board</span>}
            </span>
          </div>
          <div className="col-md-7">
            <div>
              <div id="whatsnew_container" style={{ display: this.state.view == 1 ? "" : "none" }}>
                <div>
                  {this.state.Data.length == 0 ? (
                    <div className="media media-custom">
                      <div className="media-left media-middle">
                        <span className="display-icon">
                          <i className="fa fa-info"></i>
                        </span>
                      </div>
                      <div className="media-body media-middle">
                        This is a timeline that will show important updates for your office, including new mandates, price changes, incoming leads and document
                        uploads
                      </div>
                    </div>
                  ) : (
                    data.map((item, index) => {
                      return this.renderItem(item, index);
                    })
                  )}
                </div>
                <div className="row">
                  <div className="col-xs-6">
                    {/*showLess ? <div className="pull-left" onClick={() => this.onLoadMoreClick(-1)} style={{ margin: 10, cursor: 'pointer' }}><u>show less</u></div> : null */}
                  </div>
                  <div className="col-xs-6">
                    {showMore ? (
                      <div className="pull-right" onClick={() => this.onLoadMoreClick(1)} style={{ margin: 10, cursor: "pointer" }}>
                        <u>load more</u>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div id="diary_container">
                <div className="panel panel-default oms-box" style={{ display: this.state.view == 2 ? "" : "none" }}>
                  <div className="panel-heading">
                    <div className="d-flex justify-content-between">
                      <div className="">
                        <div className="form-inline form-group form-inline-filter">
                          <label>
                            <i className="fa fa-filter" aria-hidden="true"></i>
                            &nbsp;
                          </label>
                          <OMSDropdown
                            id="CalendarUserID"
                            name="CalendarUserID"
                            options={this.props.dashboardData.calendarViewDD}
                            valueField="value"
                            labelField="text"
                            value={this.state.CalendarUserIDValue}
                            onChange={this.handleCalendarViewDropdownChange}
                          />
                        </div>
                      </div>
                      <div className="mx-1">
                        <div className="pull-right">
                          {this.props.dashboardData.isReadOnly ? null : (
                            <OMSButtonStyleL
                              type="button"
                              ui_icon=""
                              ui_type="create"
                              ui_text="add"
                              ui_processing_text=""
                              processing={false}
                              disabled={false}
                              onClick={() => this.onOpenDairyItemFunc(null)}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="panel-body">
                    <div className="col-md-12">
                      <OMSDiaryNoteCalendar
                        htmlID="noteCalender"
                        ID={this.state.CalendarUserID}
                        userID={this.state.CalendarUserID}
                        CalendarState={this.state.CalendarState}
                        CalendarOfficeID={this.state.CalendarOfficeID}
                        DNType={0}
                        ShowAdd={false}
                        Display="V"
                        ref={this.noteCalenderRef}
                        onOpenDairyItemFunc={this.onOpenDairyItemFunc}
                        DiaryActionTags={this.props.dashboardData.diaryActionTags}
                      />
                    </div>
                  </div>
                </div>
                <OMSDiaryNoteItem
                  IsReadOnly={this.props.dashboardData.isReadOnly}
                  htmlID="noteItem"
                  ID="noteItem"
                  OnShowStart={this.props.dashboardData.onShowStart}
                  OnShowEnd={this.props.dashboardData.onShowEnd}
                  DNType={0}
                  ref={this.noteItemRef}
                  onDiaryItemSubmitCompleteFunc={this.onDiaryItemSubmitCompleteFunc}
                  DiaryActionTags={this.props.dashboardData.diaryActionTags}
                />
              </div>
              <div id="lead_container">
                <OMSLead
                  IsReadOnly={this.props.dashboardData.isReadOnly}
                  OfficeContactTags={this.props.dashboardData.officeContactTags}
                  UserContactTags={this.props.dashboardData.userContactTags}
                  ContactSourceTypes={this.props.dashboardData.contactSourceTypes}
                  DialingCode={this.props.dashboardData.dialingCode}
                  DialingCodeDD={this.props.dashboardData.dialingCodeDD}
                  ref={this.myLeadBoxRef}
                  ID="myLeadBox"
                  UserID=""
                  View={1}
                  RefreshFun={this.RefreshDashboardItems}
                  show={this.state.view == 3}
                  onMessageClick={this.onMessageItemClick}
                />
              </div>
              <div id="kpi_container" style={{ display: this.state.view == 4 ? "" : "none" }}>
                <div className="panel panel-default oms-box">
                  <div className="panel-heading">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-inline form-group">
                          <label>
                            <i className="fa fa-filter" aria-hidden="true"></i>
                            &nbsp;
                          </label>
                          <select className="form-control" onChange={this.handleKPIViewChange} value={this.state.KPI_view}>
                            <option value="1">Company KPI</option>
                            <option value="2">Facebook KPI</option>
                            <option value="3">Google search KPI</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="panel-body">
                    <div className="col-md-12">
                      <div style={{ padding: "63% 0 0 0", position: "relative" }}>
                        <iframe
                          src={
                            this.state.view == 4
                              ? this.state.KPI_view == 3
                                ? "https://app.databox.com/datawall/cdb9e8ad6c313e89ffc8e29a99f0452105d37effa?i"
                                : this.state.KPI_view == 2
                                ? "https://app.databox.com/datawall/40d9bf2ac24a139f117176230477a0bd05d37f156?i"
                                : "https://datastudio.google.com/embed/u/0/reporting/303c276d-4366-4fc7-8f8e-65c9304a3ff6/page/1M"
                              : ""
                          }
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                          }}
                          frameBorder="0"
                          webkitallowfullscreen="true"
                          mozallowfullscreen="true"
                          allowFullScreen={true}
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="noticeboard_container" style={{ display: this.state.view == 5 ? "" : "none" }}>
                <div className="panel panel-default oms-box">
                  <div className="panel-heading">
                    <div className="row">
                      <div className="col-md-12">
                        <label>
                          <i className="fa fa-bullhorn" aria-hidden="true"></i>
                          &nbsp; Notice board{" "}
                        </label>
                        <span>{this.props.dashboardData.lastUpdated ? "- last updated " + this.props.dashboardData.lastUpdated : null}</span>
                      </div>
                    </div>
                  </div>
                  <div className="panel-body">
                    <div className="row">
                      <div className="col-md-12">
                        <div
                          id="divNoticeboard"
                          dangerouslySetInnerHTML={{
                            __html: this.props.dashboardData.content,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  {this.props.dashboardData.isAdmin ? (
                    <div className="panel-footer">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="pull-right">
                            {/* <a href="/library?m=noticeboard" className="btn">
                              <span className="fa fa-edit" style={{ fontSize: 14 }}></span>
                              &nbsp;Edit
                  </a> */}
                            <Link to={"/library?m=noticeboard"} className="btn">
                              <span className="fa fa-edit" style={{ fontSize: 14 }}></span>
                              &nbsp;Edit
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 hidden-xs hidden-sm">
            <div style={{ margin: 10, padding: 5 }}>
              <div className="row" data-intercom-target="dash-stats1">
                <div className="col-md-6 text-center">
                  <div
                    className="panel panel-default oms-box"
                    style={{
                      background: "transparent",
                      padding: 5,
                      maxWidth: 135,
                      float: "right",
                    }}
                  >
                    <div className="panel-body">
                      <Link to="/Property?dashboardSectionClicked=forSale" style={{ textDecoration: "none", color: "inherit" }}>
                        <span style={{ fontSize: 35 }}>
                          <b>{this.state.ForSale}</b>
                        </span>{" "}
                        <br />
                        <span>For Sale</span> <br />
                        <span
                          data-placement="bottom"
                          data-toggle="tooltip"
                          title="Total price for active for sale listings"
                          style={{ display: "inline-block", marginTop: 5 }}
                        >
                          <i style={{ fontSize: 14 }} className="fa fa-globe"></i> {CurrencyDisplay}
                          {this.state.ForSaleAmount}
                          {this.state.ForSaleSymbol}
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 text-center">
                  <div
                    className="panel panel-default oms-box"
                    style={{
                      background: "transparent",
                      padding: 5,
                      maxWidth: 135,
                      float: "left",
                    }}
                  >
                    <div className="panel-body">
                      <Link to="/Property?dashboardSectionClicked=forRent" style={{ textDecoration: "none", color: "inherit" }}>
                        <span style={{ fontSize: 35 }}>
                          <b>{this.state.ForRent}</b>
                        </span>{" "}
                        <br />
                        <span>For Rent</span> <br />
                        <span
                          data-placement="bottom"
                          data-toggle="tooltip"
                          title="Total price for active for rent listings"
                          style={{ display: "inline-block", marginTop: 5 }}
                        >
                          <i style={{ fontSize: 14 }} className="fa fa-globe"></i> {CurrencyDisplay}
                          {this.state.ForRentAmount}
                          {this.state.ForRentSymbol}
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div style={{ margin: 10, padding: 5 }}>
              <div className="row" data-intercom-target="dash-stats2">
                <div className="col-md-4">
                  <Link to="/Property?dashboardSectionClicked=syndication" style={{ textDecoration: "none", color: "inherit" }}>
                    {this.renderCircle("fa-check-circle", "green", this.state.Active, "Active", "Feed status: number of listings active")}
                  </Link>
                </div>
                <div className="col-md-4 text-center">
                  <Link to="/Property?dashboardSectionClicked=syndication" style={{ textDecoration: "none", color: "inherit" }}>
                    {this.renderCircle("fa-clock-o", "orange", this.state.Pending, "Pending", "Feed status: number of listings pending")}
                  </Link>
                </div>
                <div className="col-md-4 text-center">
                  <Link to="/Property?dashboardSectionClicked=syndication" style={{ textDecoration: "none", color: "inherit" }}>
                    {this.renderCircle("fa-exclamation-triangle", "red", this.state.Errors, "Errors", "Feed status: number of listings errors")}
                  </Link>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 text-center">
                {/*<p style={{ marginTop: 20 }}><i className="fa fa-bell-o" style={{ fontSize: 20, marginRight: 10 }} aria-hidden="true"></i><i className="fa fa-circle" style={{ fontSize: 14, marginRight: 5, color: this.state.status_color }} aria-hidden="true"></i><a href="http://status.entegral.net/" target="_blank" style={{ cursor: 'pointer' }}>{this.state.status_description}</a></p>*/}
                {/*<p style={{ marginTop: 20 }}><i className="fa fa-bell-o" style={{ fontSize: 20, marginRight: 10 }} aria-hidden="true"></i><a href="http://status.entegral.net/" target="_blank" style={{ cursor: 'pointer' }}>status.entegral.net</a></p>*/}
              </div>
            </div>
          </div>
          <OMSSendMessage ref={this.sendMessageRef} ID="CSND" onSuccess={this.onSendMessageSuccess} />
        </div>
        <br />
        <br />
      </div>
    );
  }
}

export default withRouter(Dashboard);
