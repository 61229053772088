import React from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import { OMSButtonStyleL } from "../../../../app/common/common-components/OMSButton";

const ListingViewsModal = ({
  mViewReportFrom,
  mViewReportTo,
  mProcessing,
  handleListingViewsRunClick,
  handleViewsPerPortalsChanged,
  setViewReportFrom,
  setViewReportTo,
}) => {
  return (
    <div className="modal fade" id="listingViewReportModel" tabIndex={-1} aria-labelledby="listingViewReportModelLabel" aria-hidden="true">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">×</button>
            <h4 className="modal-title" id="listingViewReportModelLabel">Listing Views Report</h4>
          </div>
          <div className="modal-body">
            <div className="row" style={{ marginTop: 10 }}>
              <div className="col-md-12 col-sm-12 col-xs-12">
                <label>Date range</label>
              </div>
              <div id="mainDivForMandateDatePicker3" className="col-md-12 col-sm-12 col-xs-12 marginBottom10">
                <DatePicker
                  selected={mViewReportFrom}
                  onChange={date => setViewReportFrom(date)}
                  dateFormat="dd-MM-yyyy"
                  placeholderText="From date"
                  name="mViewReportFrom"
                  id="mViewReportFrom"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  className="form-control MandateDatePicker3"
                  isClearable={true}
                />
                <DatePicker
                  selected={mViewReportTo}
                  onChange={date => setViewReportTo(date)}
                  dateFormat="dd-MM-yyyy"
                  placeholderText="To date"
                  id="mViewReportTo"
                  name="mViewReportTo"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  className="form-control MandateDatePicker3"
                  isClearable={true}
                />
                <label className="switch" style={{ marginTop: 10 }}>
                  <input
                    type="checkbox"
                    id="ViewsPerPortals"
                    name="ViewsPerPortals"
                    defaultChecked={true}
                    onChange={handleViewsPerPortalsChanged}
                  />
                  <div className="slider round" />
                  <span id="spanReportViewText" style={{ marginLeft: 70, display: "block", width: 120, marginTop: 7 }}>
                    Views per portal
                  </span>
                </label>
              </div>
            </div>
            <div className="row" style={{ marginTop: 25, marginBottom: 15 }}>
              <div className="col-md-12">
                <div id="PageViewChart"></div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="pull-left">
              <a href="https://help.entegral.net/en/articles/5631079-listing-view-reports" target="_blank">Help with listing views</a>
            </div>
            <a style={{ marginRight: 20, cursor: "pointer" }} data-dismiss="modal">Close</a>
            <OMSButtonStyleL
              type="button"
              ui_icon="fa-check"
              ui_type="save"
              ui_text="View"
              ui_processing_text="Viewing.."
              processing={mProcessing}
              disabled={mProcessing}
              onClick={handleListingViewsRunClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

ListingViewsModal.propTypes = {
  mViewReportFrom: PropTypes.instanceOf(Date).isRequired,
  mViewReportTo: PropTypes.instanceOf(Date).isRequired,
  mProcessing: PropTypes.bool.isRequired,
  handleListingViewsRunClick: PropTypes.func.isRequired,
  handleViewsPerPortalsChanged: PropTypes.func.isRequired,
  setViewReportFrom: PropTypes.func.isRequired,
  setViewReportTo: PropTypes.func.isRequired,
};

export default ListingViewsModal;
