import React, { useContext, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import dragContext from "../../DragStore/DragContext";
import { useHistory } from "react-router";
import AjaxLoader from "../../../../app/common/common-components/AjaxLoader";
import Progressbar from "../../common/Progressbar";
import { useStore } from "../../../../app/stores/store";
import OMSTooltip from "../../../../app/common/common-components/OMSTooltip/OMSTooltip";

const PipelineSales = ({
  pipelineDD,
  workspaceDD,
  currencySymbol,
  accessType,
  actions,
  filterData,
  loopFilterOpen,
  loopFilterChanged,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [pipelineDisplayData, setPipelineDisplayData] = useState([]);
  const state = useContext(dragContext);
  let history = useHistory();

  useEffect(() => {
    console.log("useEffect >> PipelineSales");
    console.log(filterData);
    setIsLoading(true);
    state.setFilterData(filterData);
    setSearch("");
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [filterData]);

  const handleWorkspaceDDChange = (wId, wName) => {
    var mFilterData = filterData.getCopy();
    mFilterData.WorkspaceAgentId = wId;
    mFilterData.WorkspaceAgentName = wName;
    mFilterData.NeedUpdate = true;
    loopFilterChanged(mFilterData);
  };

  const formatTaskStatus = () => {};
  const handlePipelineChange = (pId, pName) => {
    var mFilterData = filterData.getCopy();
    mFilterData.WorkspacePipelineId = pId;
    mFilterData.WorkspacePipeline = pName;
    mFilterData.NeedUpdate = true;
    loopFilterChanged(mFilterData);
  };

  const getAmountFormat = (number) => {
    if (isNaN(number)) return "0";

    var SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];
    var tier = (Math.log10(Math.abs(number)) / 3) | 0;

    // if zero, we don't need a suffix
    if (tier == 0) return number.toFixed(1);

    // get suffix and determine scale
    var suffix = SI_SYMBOL[tier];
    var scale = Math.pow(10, tier * 3);

    // scale the number
    var scaled = number / scale;

    // format number and add suffix
    return scaled.toFixed(1) + suffix;
  };

  const getAllDealAmount = (deals, t) => {
    var totalAmount = 0;
    for (var i = 0; i < deals.length; i++) {
      if (t == "P") {
        totalAmount += isNaN(deals[i].purchaseAmount)
          ? 0
          : deals[i].purchaseAmount;
      } else {
        totalAmount += isNaN(deals[i].grossNettCommision)
          ? 0
          : deals[i].grossNettCommision;
      }
    }
    return totalAmount;
  };

  const getAllDealRedPercentage = (deals) => {
    var totalTasks = 0;
    var completedTasks = 0;
    for (var i = 0; i < deals.length; i++) {
      totalTasks += deals[i].totalTasks;
      completedTasks += deals[i].completedTaskRed;
    }

    if (totalTasks == 0) return 0;
    else return (completedTasks * 100) / totalTasks;
  };

  const getAllDealGreenPercentage = (deals) => {
    var totalTasks = 0;
    var completedTasks = 0;
    for (var i = 0; i < deals.length; i++) {
      totalTasks += deals[i].totalTasks;
      completedTasks += deals[i].completedTaskGreen;
    }

    if (totalTasks == 0) return 0;
    else return (completedTasks * 100) / totalTasks;
  };

  useEffect(() => {
    var mainData = JSON.parse(JSON.stringify(state.pipelineData));
    if (search) {
      var searchL = search.toLocaleLowerCase();
      mainData.forEach((element) => {
        var deals = [...element.deals];
        element.deals = deals.filter(function (e) {
          return e.dealName.toLowerCase().indexOf(searchL) != -1;
        });
      });
    }
    setPipelineDisplayData(mainData);
  }, [search, state.pipelineData]);

  return (
    <>
      <DragDropContext onDragEnd={state.onDragEnd}>
        <div className="row mt-2">
          <div className="col-md-12">
            <div className="d-flex align-items-center">
              <div className="dropdown card-body mx-2">
                <h6
                  class="dropdown-toggle cursor-pointer card-subtitles"
                  data-toggle="dropdown"
                >
                  {filterData.WorkspaceAgentName}
                  <b class="caret"></b>
                </h6>
                {/* {accessType == 1 ? ( */}
                  <ul class="dropdown-menu">
                    {workspaceDD?.map((curStatus, i) => {
                      return (
                        <li
                          key={curStatus.value}
                          onClick={() =>
                            handleWorkspaceDDChange(
                              curStatus.Value,
                              curStatus.Text
                            )
                          }
                        >
                          <a>{curStatus.Text}</a>
                        </li>
                      );
                    })}
                  </ul>
                {/* ) : null} */}
              </div>
              <div className="dropdown mx-3">
                <span
                  class="dropdown-toggle text-light cursor-pointer"
                  data-toggle="dropdown"
                >
                  {" "}
                  pipeline: {filterData.WorkspacePipeline}
                  <b class="caret"></b>
                </span>
                <ul class="dropdown-menu">
                  {pipelineDD?.map((curElem, i) => {
                    return (
                      <li
                        key={curElem.Value}
                        onClick={() =>
                          handlePipelineChange(curElem.Value, curElem.Text)
                        }
                      >
                        <a>{curElem.Text}</a>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="mx-3">
                <div
                  className="form-inline form-group"
                  style={{ marginBottom: 0 }}
                >
                  <div className="element">
                    <div className="truncate">
                      <label>
                        <i className="fa fa-filter" aria-hidden="true"></i>
                        &nbsp;
                      </label>
                      <a
                        className="filter-text"
                        onClick={() => loopFilterOpen("Pipeline")}
                      >
                        <span>
                          <b>Filter: </b>
                          {filterData.getPipelineFilterStringFull()}
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mx-3">
                <div>
                  <div
                    class="form-group has-feedback"
                    style={{ marginBottom: 0 }}
                  >
                    <span
                      class="fa fa-search form-control-feedback"
                      style={{ marginTop: 6 }}
                    ></span>
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => setSearch(e.target.value)}
                      value={search}
                      name="search-input"
                      placeholder="Search"
                      id="search-input"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {state?.isDataLoading || isLoading ? (
          <AjaxLoader />
        ) : (
          <div className="row mt-2">
            <div className="drag_cards d-flex" style={{ height: "80vh" }}>
              {pipelineDisplayData.map((curStage, i) => {
                console.log("Stage ", curStage);
                return (
                  <div className="card_item" key={i}>
                    <div className="stick_header_pipeline">
                      <div className="d-flex p-1 mx-3">
                        <OMSTooltip
                          infoText={
                            <>
                              <div className="row">
                                <div className="col">
                                  <span
                                    style={{ fontWeight: "bold" }}
                                    className="text-success text-center"
                                  >
                                    Completed Tasks:{" "}
                                    {curStage.completedStageTaskGreen}
                                  </span>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col">
                                  <span
                                    style={{ fontWeight: "bold" }}
                                    className="text-danger text-center "
                                  >
                                    Overdue Tasks:{" "}
                                    {curStage.completedStageTaskRed}
                                  </span>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col">
                                  <span
                                    style={{ fontWeight: "bold" }}
                                    className="text-dark text-center"
                                  >
                                    Outstanding Tasks:{" "}
                                    {curStage.completedStageTaskGrey}
                                  </span>
                                </div>
                              </div>
                            </>
                          }
                        >
                          <div
                            className="black_circle"
                            style={{ marginLeft: 8 }}
                          >
                            <Progressbar
                              redCounts={getAllDealRedPercentage(
                                curStage.deals
                              )}
                              greenCounts={getAllDealGreenPercentage(
                                curStage.deals
                              )}
                            />
                          </div>
                        </OMSTooltip>
                        <div className="d-flex flex-column mx-2">
                          <span>
                            {" "}
                            <strong>{curStage.stageName}</strong>{" "}
                          </span>
                          <span className="small_caption">
                            {`${
                              curStage.deals.length
                            } Transactions | ${currencySymbol}${getAmountFormat(
                              getAllDealAmount(curStage.deals, "P")
                            )} Sales | ${currencySymbol}
                            ${getAmountFormat(
                              getAllDealAmount(curStage.deals, "G")
                            )} GC
                      `}
                          </span>
                        </div>
                      </div>
                    </div>

                    <Droppable droppableId={curStage.stageId}>
                      {(provided) => (
                        <div className="container-drag">
                          <div
                            className="mt-4 drag_drop_container p-2 mx-3"
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            {curStage.deals?.map((curElem, i) => {
                              return (
                                <Draggable
                                  key={curElem.dealID}
                                  draggableId={curElem.dealID}
                                  index={i}
                                >
                                  {(provided) => (
                                    <div
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      ref={provided.innerRef}
                                      className="card_element mt-2 p-2"
                                    >
                                      <div className="d-flex align-items-center">
                                        <div
                                          className=""
                                          style={{ marginLeft: "3.5px" }}
                                        >
                                          <div className="black_circle">
                                            <OMSTooltip
                                              infoText={
                                                <>
                                                  <div className="row">
                                                    <div className="col">
                                                      <span
                                                        style={{
                                                          fontWeight: "bold",
                                                        }}
                                                        className="text-success text-center"
                                                      >
                                                        Completed Tasks:{" "}
                                                        {
                                                          curElem.completedTaskGreen
                                                        }
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <div className="row">
                                                    <div className="col">
                                                      <span
                                                        style={{
                                                          fontWeight: "bold",
                                                        }}
                                                        className="text-danger text-center "
                                                      >
                                                        Overdue Tasks:{" "}
                                                        {
                                                          curElem.completedTaskRed
                                                        }
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <div className="row">
                                                    <div className="col">
                                                      <span
                                                        style={{
                                                          fontWeight: "bold",
                                                        }}
                                                        className="text-dark text-center"
                                                      >
                                                        Outstanding Tasks:{" "}
                                                        {
                                                          curElem.completedTaskGrey
                                                        }
                                                      </span>
                                                    </div>
                                                  </div>
                                                </>
                                              }
                                            >
                                              <Progressbar
                                                redCounts={
                                                  curElem.totalTasks == 0
                                                    ? 0
                                                    : (curElem.completedTaskRed *
                                                        100) /
                                                      curElem.totalTasks
                                                }
                                                greenCounts={
                                                  curElem.totalTasks == 0
                                                    ? 0
                                                    : (curElem.completedTaskGreen *
                                                        100) /
                                                      curElem.totalTasks
                                                }
                                              />
                                            </OMSTooltip>
                                          </div>
                                        </div>
                                        <div className="mx-2 p-2 d-flex flex-column">
                                          <div className="">
                                            <span
                                              className="element_link"
                                              onClick={() => {
                                                history.push(
                                                  `/deal/saveDeal?ID=${curElem.dealID}`
                                                );
                                              }}
                                            >
                                              {curElem.dealName}
                                            </span>
                                          </div>
                                          {curElem.registrationDate ? (
                                            <div
                                              className=""
                                              style={{
                                                fontSize: "12px",
                                                marginTop: 4,
                                                marginBottom: 2,
                                              }}
                                            >
                                              <i
                                                className="fa fa-calendar"
                                                style={{ fontSize: "12px" }}
                                              ></i>{" "}
                                              <span>
                                                Expected Registration:{" "}
                                                {curElem.registrationDate}
                                              </span>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                          <div className="tags ">
                                            <span className="tag1 bg-oms1 mr-1">{`${currencySymbol} ${getAmountFormat(
                                              curElem.purchaseAmount
                                            )}`}</span>
                                            <span className="tag2 bg-oms">{`${currencySymbol} ${getAmountFormat(
                                              curElem.grossNettCommision
                                            )} GC`}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </Droppable>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </DragDropContext>
    </>
  );
};

export default PipelineSales;
