import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";

export class OMSMultiselectDropdown extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    value: PropTypes.array,
    valueField: PropTypes.string,
    labelField: PropTypes.string,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    value: [],
    valueField: "value",
    labelField: "label",
    onChange: null,
  };

  getSelectedName = (selected) => {
    if (selected === undefined || selected == null || selected.length == 0) return "None selected";
    else {
      var count = selected.length;
      var selectedName = "";
      if (count > 3) selectedName = "(" + count + ") selected.";
      else {
        for (var i = 0; i < this.props.options.length; i++) {
          var valueText = this.props.options[i][this.props.valueField];
          if (selected.indexOf(valueText) > -1) {
            selectedName = selectedName + "," + this.props.options[i][this.props.labelField];
          }
        }
        selectedName = selectedName.substr(1, selectedName.length - 1);
      }
      return selectedName;
    }
  };

  componentDidMount() {}

  onSelectionClick = (name) => {
    var selected = this.state.selected;
    var index = selected.indexOf(name);
    if (index < 0) selected.push(name);
    else selected.splice(index, 1);

    if (this.delayTagTimer !== undefined) clearTimeout(this.delayTagTimer);

    this.delayTagTimer = setTimeout(
      function () {
        if (this.props.onChange != null) {
          this.props.onChange(this.state.selected);
        }
      }.bind(this),
      1000
    );
    this.setState({
      selected: selected,
      selectedName: this.getSelectedName(selected),
    });
  };

  state = {
    selected: this.props.value,
    selectedName: this.getSelectedName(this.props.value),
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      selected: nextProps.value,
      selectedName: this.getSelectedName(nextProps.value),
    });
  }

  render() {
    var self = this;
    var options = self.props.options?.map(function (option) {
      var valueText = option[self.props.valueField];
      return (
        <li key={valueText}>
          <a>
            <div className="checkbox" style={{ margin: 0 }}>
              <label>
                <input type="checkbox" onClick={() => self.onSelectionClick(valueText)} checked={self.state.selected.indexOf(valueText) > -1} />
                {option[self.props.labelField]}
              </label>
            </div>
          </a>
        </li>
      );
    });
    return (
      <div className="multiselect-oms-dropdown">
                <div className="dropdown multiselect-color">
                    <button className="btn btn-default dropdown-toggle form-control" type="button" data-toggle="dropdown">
                        {this.state.selectedName} &nbsp; <span className="caret" />
                    </button>
                    <ul className="dropdown-menu">
                        {options}
                    </ul>
                </div>
            </div>
    );
  }
}

class OMSMultiselectDropdown2 extends React.Component {
  static propTypes = {
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      options: PropTypes.array.isRequired,
      value: PropTypes.array,
      onChange: PropTypes.func
  };

  static defaultProps = {
      value: [],
      onChange: null
  };

  getSelectedName = (selected) => {
      if (selected === undefined || selected == null || selected.length == 0)
          return "None selected";
      else {
          var count = selected.length;
          var selectedName = '';
          if (count > 2)
              selectedName = "(" + count + ") selected.";
          else {

              for (var i = 0; i < this.props.options.length; i++) {
                  var valueText = this.props.options[i];
                  if (selected.indexOf(valueText) > -1) {
                      selectedName = selectedName + "," + this.props.options[i]
                  }
              }
              selectedName = selectedName.substr(1, selectedName.length - 1);
          }
          return selectedName;
      }
  };

  onSelectionClick = (name) => {
      var selected = this.state.selected;
      var index = selected.indexOf(name);
      if (index < 0)
          selected.push(name);
      else
          selected.splice(index, 1);

      if (this.delayTagTimer !== undefined)
          clearTimeout(this.delayTagTimer);

      this.delayTagTimer = setTimeout(function () {
          if (this.props.onChange != null) {
              this.props.onChange({ name: this.props.name, newValue: this.state.selected});
          }
      }.bind(this), 1000);
      this.setState({ selected: selected, selectedName: this.getSelectedName(selected) });
  };

  state = {
      selected: this.props.value, selectedName: this.getSelectedName(this.props.value)
  };

  componentWillReceiveProps(nextProps) {
      this.setState({
          selected: this.props.value, selectedName: this.getSelectedName(this.props.value)
      });
  }

  render() {

      var self = this;
      var options = self.props.options.map(function (option) {
          return (
              <li key={option}>
                  <a>
                      <div className="checkbox" style={{ margin: 0 }}>
                          <label><input type="checkbox" style={{marginRight:"5px"}} onClick={() => self.onSelectionClick(option)} checked={self.state.selected.indexOf(option) > -1} />{option}</label>
                      </div>
                  </a>
              </li>
          )
      });
      return (
          <div className="multiselect-oms-dropdown">
              <div className="dropdown multiselect-color">
                  <button className="btn btn-default dropdown-toggle form-control" type="button" data-toggle="dropdown">
                      {this.state.selectedName} &nbsp; <span className="caret" />
                  </button>
                  <ul className="dropdown-menu">
                      {options}
                  </ul>
              </div>
          </div>
      )
  }
}

export {OMSMultiselectDropdown2}